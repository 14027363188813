import { siteTitle, siteUrl } from './wording';

function getDocumentTitle () {
  return typeof document !== "undefined" ? document.title : siteTitle;
}

function getDocumentUrl () {
  return typeof window !== "undefined" ? window.location.href : siteUrl;
}

export { getDocumentTitle, getDocumentUrl };