"use client"

import React, { useState, useMemo } from 'react';
import Link from 'next/link';
import { HStack, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { AiOutlineCheck } from 'react-icons/ai';
import { generalAchievements } from 'utils';
import { useUser, useGlobal } from 'src/context';
import AchievementRow from './AchievementRow';

export const CHALLENGE_STATUS = {
  COMPLETE: "COMPLETE",
  OPEN: "OPEN",
  EXPIRED: "EXPIRED"
};

const CHALLENGE_KEYS = ["challenge", "gerneral"];

const AchievementsPageLayout = (props) => {
  const { data } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <>
      <div className='w-full'>
        <div className='shadow-md mb-8'>
          <Tabs
            index={selectedTabIndex}
            onChange={handleTabClick}
            isFitted
            >
            <TabList>
              <Tab>鐵粉成就</Tab>
              <Tab>傑出成就</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ChallengeAchievementsPanel data={data} />
              </TabPanel>
              <TabPanel>
                <GeneralAchievements data={data} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
        <Description index={selectedTabIndex} /> 
      </div>
    </>
  )
};

const ChallengeAchievementsPanel = ({ data }) => {
  const { isLogin, user: { challenges } } = useUser();

  const [selectedSort, setSelectedSort] = useState("passedDesc"); // passedDesc, passedAsc, new, challengeable

  const sortedChallenges = useMemo(() => {
    if (data && data.challenge) {
      return Object.keys(data.challenge).map(challengeId => {
        return data.challenge[challengeId];
      }).filter(challenge => {
        if (selectedSort === "new") {
          return challenge.isNew;
        } else if (selectedSort === "challengeable") {
          return challenges[challenge.id] && (challenges[challenge.id] === CHALLENGE_STATUS.OPEN)
        } else {
          return true;
        }
      }).sort((a, b) => {
        if (selectedSort === "passedAsc") {
          return parseInt(a.passedUsers) - parseInt(b.passedUsers);
        } else {
          return parseInt(b.passedUsers) - parseInt(a.passedUsers);
        }
      });
    } else {
      return null
    }
  }, [data, selectedSort]);

  const handleSortChange = (sort) => {
    if (sort === selectedSort) return;

    setSelectedSort(sort);
  }

  return (
    <div>
      <HStack justify='space-between'>
        <HStack spacing={3}>
          <Tag 
            borderRadius='full'
            variant={selectedSort === 'passedDesc' ? 'solid' : 'outline'}
            colorScheme='blue'
            size='md'
            _hover={{ cursor: selectedSort === 'passedDesc' ? 'default' : 'pointer' }}
            onClick={() => handleSortChange("passedDesc")}
          >
            {selectedSort === 'passedDesc' && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
            <TagLabel>熱門</TagLabel>
          </Tag>

          <Tag 
            borderRadius='full'
            variant={selectedSort === 'passedAsc' ? 'solid' : 'outline'}
            colorScheme='blue'
            _hover={{ cursor: selectedSort === 'passedAsc' ? 'default' : 'pointer' }}
            onClick={() => handleSortChange("passedAsc")}
          >
            {selectedSort === 'passedAsc' && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
            <TagLabel>稀有</TagLabel>
          </Tag>

          <Tag 
            borderRadius='full'
            variant={selectedSort === 'new' ? 'solid' : 'outline'}
            colorScheme='blue'
            _hover={{ cursor: selectedSort === 'new' ? 'default' : 'pointer' }}
            onClick={() => handleSortChange("new")}
          >
            {selectedSort === 'new' && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
            <TagLabel>最新</TagLabel>
          </Tag>

          {isLogin && (
            <Tag 
              borderRadius='full'
              variant={selectedSort === 'challengeable' ? 'solid' : 'outline'}
              colorScheme='secondary'
              _hover={{ cursor: selectedSort === 'challengeable' ? 'default' : 'pointer' }}
              onClick={() => handleSortChange("challengeable")}
            >
              {selectedSort === 'challengeable' && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
              <TagLabel>可挑戰</TagLabel>
            </Tag>
          )}
        </HStack>
        <p className='text-xs md:text-sm'>
          達成人數
        </p>
      </HStack>

      {sortedChallenges.map((acvData, idx) => {
        let node = null, hasAchieved = false;

        if (isLogin) {
          if (acvData.isExpired) {
            node = (
              <Button 
                isDisabled 
                variant="ghost" 
                size={["xs", "xs", "sm"]} 
                p={[0, 0, 4]}
              >
                挑戰終止
              </Button>
            )
          } else if (challenges[acvData.id]) {
            if (challenges[acvData.id] === CHALLENGE_STATUS.COMPLETE) {
              hasAchieved = true;
            } else if (challenges[acvData.id] === CHALLENGE_STATUS.OPEN) {
              node = (
                <Button 
                  colorScheme="secondary" 
                  variant="ghost" 
                  size={["xs", "xs", "sm"]} 
                  p={[0, 0, 4]}
                >
                  可挑戰
                </Button>
              )
            } 
          } else {
            node = (
              <Button 
                isDisabled 
                variant="ghost" 
                size={["xs", "xs", "sm"]} 
                p={[0, 0, 4]}
              >
                資格不符
              </Button>
            )
          }
        }

        return (
          <Link
            key={`challenge-acv-${idx}`} 
            href={`/firm/${acvData.firmId}`}
          >
            <AchievementRow 
              acvData={acvData}
              hasAchieved={hasAchieved}
              count={acvData.passedUsers}
              isClickable
            >
              {node}
            </AchievementRow>
          </Link>
        )
      })}
    </div>
  )
};

const GeneralAchievements = ({ data }) => {
  const { isLogin, user: { achievements } } = useUser();

  return (
    <div>
      <p className="w-full text-right text-xs md:text-sm">
        達成人數比例
      </p>
      {generalAchievements.map((acvData, idx) => {
        return (
          <AchievementRow 
            key={`general-acv-${idx}`} 
            isShowBg
            hasAchieved={isLogin && achievements.indexOf(acvData.id) > -1}
            acvData={acvData} 
            rate={data && data.general && data.general[acvData.id] ? data.general[acvData.id] : null}
          />
        )
      })}
    </div>
  )
};

const Description = ({ index }) => {
  switch (index) {
    case 1:
      return (
        <ul className="ml-6 color-slate-200 text-sm leading-loose list-disc">
          <li>本頁面定時更新</li>
          <li>所有計算以使用者的評價數量為基礎</li>
          <li>達成人數比例之計算為：達成此成就之使用者數量 ÷ 本站使用者數量</li>
          <li>越多使用者貢獻評價，比例越能呈現整體玩家分佈</li>
        </ul>
      )
    case 0:
    default:
      return (
        <ul className="ml-6 color-slate-200 text-sm leading-loose list-disc">
          <li>本頁面定時更新</li>
          <li>達成人數為：挑戰成功之使用者數量</li>
          <li>先評價該工作室足夠數量遊戲，方能取得「鐵粉挑戰」資格</li>
          <li>「鐵粉挑戰」成功，才算取得「鐵粉成就」</li>
          <li>如何獲得「鐵粉挑戰」資格請參考成就的文字描述，或者來信逃脫吧 Facebook 粉絲專頁</li>
          <li>更多鐵粉挑戰與成就陸續釋出，敬請期待</li>
        </ul>
      )
  }
}

export default AchievementsPageLayout;