import { isProdEnv } from 'utils';

function trackEvent ({ category = '', action = '', label = '', value = 0 }) {
  if (typeof window !== "undefined" && window.dataLayer && isProdEnv) {
    const userId = localStorage.getItem("customId") || "";

    window.dataLayer.push({
      event: 'customEvent',
      category,
      action,
      label,
      value,
      userId
    })
  }
};

export default trackEvent;