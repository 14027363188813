export const generalAchievementsMap = {
  CATEGORY_ESCAPE_LV1: {
    title: "新警察",
    intro: "玩過 5 場以上【密室逃脫】，請大家推薦必玩的密室",
    imageSrc: "/img/trophy-brozen.png",
    seq: 111,
    isHidden: false
  },
  CATEGORY_ESCAPE_LV2: {
    title: "巡察長",
    intro: "玩過 20 場以上【密室逃脫】，暴力破解能力還不及兩津巡察長",
    imageSrc: "/img/trophy-silver.png",
    seq: 112,
    isHidden: false
  },
  CATEGORY_ESCAPE_LV3: {
    title: "通靈王",
    intro: "玩過 50 場以上【密室逃脫】，不小心通個靈就輕鬆過關",
    imageSrc: "/img/trophy-golden.png",
    seq: 113,
    isHidden: false
  },
  CATEGORY_ESCAPE_LV4: {
    title: "我要成為密室王",
    intro: "玩過 100 場以上【密室逃脫】，去找吧！我把題目都放在那了！",
    imageSrc: "/img/trophy-diamond.png",
    seq: 114,
    isHidden: false
  },
  CATEGORY_REALITY_LV1: {
    title: "給開司一份謎題包",
    intro: "玩過 5 場以上【實境解謎】，也來罐啤酒加速腦袋運轉",
    imageSrc: "/img/trophy-brozen.png",
    seq: 121,
    isHidden: false
  },
  CATEGORY_REALITY_LV2: {
    title: "少年偵探團",
    intro: "玩過 20 場以上【實境解謎】，請留意附近是否有戴眼鏡的男孩",
    imageSrc: "/img/trophy-silver.png",
    seq: 122,
    isHidden: false
  },
  CATEGORY_REALITY_LV3: {
    title: "以我爺爺的名義",
    intro: "玩過 50 場以上【實境解謎】，不要問我爺爺姓什麼你會怕",
    imageSrc: "/img/trophy-golden.png",
    seq: 123,
    isHidden: false
  },
  CATEGORY_REALITY_LV4: {
    title: "特級偵探",
    intro: "玩過 100 場以上【實境解謎】，所以我說那個謎題呢？",
    imageSrc: "/img/trophy-diamond.png",
    seq: 124,
    isHidden: true
  },
  CATEGORY_SCENARIO_LV1: {
    title: "劇本殺新人",
    intro: "玩過 5 場以上【劇本殺】，請多指教！",
    imageSrc: "/img/trophy-brozen.png",
    seq: 131,
    isHidden: true
  },
  CATEGORY_SCENARIO_LV2: {
    title: "劇本殺達人",
    intro: "玩過 20 場以上【劇本殺】",
    imageSrc: "/img/trophy-silver.png",
    seq: 132,
    isHidden: true
  },
  CATEGORY_SCENARIO_LV3: {
    title: "劇本殺魔人",
    intro: "玩過 50 場以上【劇本殺】",
    imageSrc: "/img/trophy-golden.png",
    seq: 133,
    isHidden: true
  },
  CATEGORY_SCENARIO_LV4: {
    title: "劇本殺大神",
    intro: "玩過 100 場以上【劇本殺】",
    imageSrc: "/img/trophy-diamond.png",
    seq: 134,
    isHidden: true
  },
  DIFF_FIRMS_LV1: {
    title: "實境遊戲的闖關者",
    intro: "在 5 間店家玩過實境遊戲，為了簽證天數努力拼搏著",
    imageSrc: "/img/trophy-brozen.png",
    seq: 211,
    isHidden: false
  },
  DIFF_FIRMS_LV2: {
    title: "目標是成為實境遊戲大師",
    intro: "在 20 間店家玩過實境遊戲，下一間店家在哪裡？",
    imageSrc: "/img/trophy-silver.png",
    seq: 212,
    isHidden: false
  },
  DIFF_FIRMS_LV3: {
    title: "孤獨的踩雷家",
    intro: "在 50 間店家玩過實境遊戲，隊友好難找...",
    imageSrc: "/img/trophy-golden.png",
    seq: 213,
    isHidden: false
  },
  DIFF_TW_CITIES_LV1: {
    title: "進擊的調查兵團",
    intro: "在 5 個城市玩過實境遊戲，知道外頭有什麼好玩的",
    imageSrc: "/img/trophy-brozen.png",
    seq: 221,
    isHidden: false
  },
  DIFF_TW_CITIES_LV2: {
    title: "教練我好想玩實境遊戲",
    intro: "在 10 個城市玩過實境遊戲，全國制霸進行中",
    imageSrc: "/img/trophy-silver.png",
    seq: 222,
    isHidden: false
  },
  CITY_101_LV1: {
    title: "台北直直撞",
    intro: "在【台北市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 31011,
    isHidden: false
  },
  CITY_101_LV2: {
    title: "天龍人的偽裝",
    intro: "在【台北市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31012,
    isHidden: false
  },
  CITY_102_LV1: {
    title: "北漂青年",
    intro: "在【新北市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 31021,
    isHidden: false
  },
  CITY_102_LV2: {
    title: "巷子內的行家",
    intro: "在【新北市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31022,
    isHidden: false
  },
  CITY_103_LV1: {
    title: "基隆人",
    intro: "在【基隆市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31011,
    isHidden: true
  },
  CITY_103_LV2: {
    title: "基隆通",
    intro: "在【基隆市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 31012,
    isHidden: true
  },
  CITY_104_LV1: {
    title: "大家都是桃園人",
    intro: "在【桃園市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 31041,
    isHidden: false
  },
  CITY_104_LV2: {
    title: "航空城的加持",
    intro: "在【桃園市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31042,
    isHidden: false
  },
  CITY_105_LV1: {
    title: "新竹林七賢",
    intro: "在【新竹市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 31051,
    isHidden: false
  },
  CITY_105_LV2: {
    title: "攻城獅的精神",
    intro: "在【新竹市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31052,
    isHidden: false
  },
  CITY_106_LV1: {
    title: "新竹人",
    intro: "在【新竹縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 31061,
    isHidden: true
  },
  CITY_106_LV2: {
    title: "新竹通",
    intro: "在【新竹縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 31062,
    isHidden: true
  },
  CITY_107_LV1: {
    title: "蘭陽好風光",
    intro: "在【宜蘭縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 31071,
    isHidden: false
  },
  CITY_107_LV2: {
    title: "宜蘭通",
    intro: "在【宜蘭縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 31072,
    isHidden: true
  },
  CITY_201_LV1: {
    title: "苗栗人",
    intro: "在【苗栗縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 32011,
    isHidden: true
  },
  CITY_201_LV2: {
    title: "苗栗通",
    intro: "在【苗栗縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 32012,
    isHidden: true
  },
  CITY_202_LV1: {
    title: "再會中港路",
    intro: "在【台中市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 32021,
    isHidden: false
  },
  CITY_202_LV2: {
    title: "全村的希望",
    intro: "在【台中市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 32022,
    isHidden: false
  },
  CITY_203_LV1: {
    title: "彰化人",
    intro: "在【彰化縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 32031,
    isHidden: true
  },
  CITY_203_LV2: {
    title: "彰化通",
    intro: "在【彰化縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 32032,
    isHidden: true
  },
  CITY_204_LV1: {
    title: "南投人",
    intro: "在【南投縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 32041,
    isHidden: true
  },
  CITY_204_LV2: {
    title: "南投通",
    intro: "在【南投縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 32042,
    isHidden: true
  },
  CITY_205_LV1: {
    title: "雲林人",
    intro: "在【雲林縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 32051,
    isHidden: true
  },
  CITY_205_LV2: {
    title: "雲林通",
    intro: "在【雲林縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 32052,
    isHidden: true
  },
  CITY_301_LV1: {
    title: "嘉義人",
    intro: "在【嘉義市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 33011,
    isHidden: true
  },
  CITY_301_LV2: {
    title: "嘉義通",
    intro: "在【嘉義市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 33012,
    isHidden: true
  },
  CITY_302_LV1: {
    title: "嘉義人",
    intro: "在【嘉義縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 33021,
    isHidden: true
  },
  CITY_302_LV2: {
    title: "嘉義通",
    intro: "在【嘉義縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 33022,
    isHidden: true
  },
  CITY_303_LV1: {
    title: "台南人",
    intro: "在【台南市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 33031,
    isHidden: false
  },
  CITY_303_LV2: {
    title: "古都的守護",
    intro: "在【台南市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 33032,
    isHidden: false
  },
  CITY_304_LV1: {
    title: "來去高雄",
    intro: "在【高雄市】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-brozen.png",
    seq: 33041,
    isHidden: false
  },
  CITY_304_LV2: {
    title: "港都的熱情",
    intro: "在【高雄市】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 33042,
    isHidden: false
  },
  CITY_305_LV1: {
    title: "屏東人",
    intro: "在【屏東縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 33051,
    isHidden: true
  },
  CITY_305_LV2: {
    title: "屏東通",
    intro: "在【屏東縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 33052,
    isHidden: true
  },
  CITY_401_LV1: {
    title: "花蓮人",
    intro: "在【花蓮縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 34011,
    isHidden: true
  },
  CITY_401_LV2: {
    title: "花蓮人",
    intro: "在【花蓮縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 34012,
    isHidden: true
  },
  CITY_402_LV1: {
    title: "台東人",
    intro: "在【台東縣】玩過 5 場以上實境遊戲",
    imageSrc: "/img/trophy-silver.png",
    seq: 34021,
    isHidden: true
  },
  CITY_402_LV2: {
    title: "台東人",
    intro: "在【台東縣】玩過 20 場以上實境遊戲",
    imageSrc: "/img/trophy-golden.png",
    seq: 34022,
    isHidden: true
  },
};

export const generalAchievementKeys = Object.keys(generalAchievementsMap);

export const generalAchievements = generalAchievementKeys.filter(itemKey => {
  return generalAchievementsMap[itemKey].isHidden !== true
}).sort((a, b) => {
  return generalAchievementsMap[a].seq - generalAchievementsMap[b].seq
}).map(itemKey => {
  return {
    id: itemKey,
    ...generalAchievementsMap[itemKey]
  }
})

const CATEGORY_ACHIEVEMENTS_LEVEL = [["LV1", 5], ["LV2", 20], ["LV3", 50], ["LV4", 100]];
const DIFF_FIRMS_ACHIEMENTS_LEVEL = [["LV1", 5], ["LV2", 20], ["LV3", 50]];
const DIFF_TW_CITIES_ACHIEVEMENTS_LEVEL = [["LV1", 5], ["LV2", 10]];
const CITY_ACHIEVEMENTS_LEVEL = [["LV1", 5], ["LV2", 20]];

/**
 * 閹割版
 * @param {object} titleMap         遊戲主題資料
 * @param {array}  idList           所有玩過的遊戲 id
 * @returns 
 */
 export const calculateGeneralAchievementScore = ({ 
  titleMap = {}, 
  idList = [], 
}) => {

  let result = {
    gamesCountByCategory: {
      ESCAPE: 0,
      REALITY: 0,
      SCENARIO: 0,
    },
    gamesCountByFirm: {},
    gamesCountByCity: {}
  }

  idList.forEach(gameId => {
    const { firmId, cityId, category } = titleMap[gameId];

    result.gamesCountByCategory[category] += 1;

    // category === "SCENARIO" 沒有 firmId 與 cityId

    if (firmId) {
      if (firmId in result.gamesCountByFirm) {
        result.gamesCountByFirm[firmId] += 1
      } else {
        result.gamesCountByFirm[firmId] = 1
      }
    }

    if (cityId) {
      if (cityId in result.gamesCountByCity) {
        result.gamesCountByCity[cityId] += 1
      } else {
        result.gamesCountByCity[cityId] = 1
      }
    }
  });

  let score = {}

  // 遊戲類型多樣性
  const categoryKeys = Object.keys(result.gamesCountByCategory);
  categoryKeys.forEach(category => {
    CATEGORY_ACHIEVEMENTS_LEVEL.forEach(([level, value]) => {
      score[`CATEGORY_${category}_${level}`] = [result.gamesCountByCategory[category], value]
    })
  });

  // 工作室多樣性
  const firmKeys = Object.keys(result.gamesCountByFirm);

  DIFF_FIRMS_ACHIEMENTS_LEVEL.forEach(([level, value]) => {
    score[`DIFF_FIRMS_${level}`] = [firmKeys.length, value]
  })

  // 城市多樣性
  const cityKeys = Object.keys(result.gamesCountByCity);
  DIFF_TW_CITIES_ACHIEVEMENTS_LEVEL.forEach(([level, value]) => {
    score[`DIFF_TW_CITIES_${level}`] = [cityKeys.length,value]
  })

  // 城市單一性
  cityKeys.forEach(cityId => {
    CITY_ACHIEVEMENTS_LEVEL.forEach(([level, value]) => {
      score[`CITY_${cityId}_${level}`] = [result.gamesCountByCity[cityId], value]
    })
  })

  return score
};