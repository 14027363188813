import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/storage";
import 'firebase/compat/analytics';
import 'firebase/compat/performance';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_API_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

let ebFirebase;
let auth;
let storage;
let avatarRef;
let reviewPhotoRef;

function initFirebase() {
  try {
    ebFirebase = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
    auth = ebFirebase.auth();
    storage = ebFirebase.storage();
    
    const storageRef = storage.ref();
    avatarRef = storageRef.child('avatars');
    reviewPhotoRef = storageRef.child('reviewPhotos');
  
    auth.useDeviceLanguage();
  
    if (typeof window !== 'undefined') {
      firebase.analytics()
      firebase.performance()
    }
  } catch (err) {
    // console.log(`cannot initialize firebase, maybe we're running next build?`);
    console.log(`>> CATCH FIREBASE ERROR:`)
    console.log(err)
  }
}

function createProvider(authProvider) {
  const provider = authProvider === 'google'
    ? new firebase.auth.GoogleAuthProvider() 
    : new firebase.auth.FacebookAuthProvider()
    ;

  return provider;
};

export {
  initFirebase,
  ebFirebase,
  auth,
  storage,
  avatarRef,
  reviewPhotoRef,
  createProvider
};