export const peopleAmountOptions = [
  {
    value: '',
    name: "不限",
  },
  {
    value: '1',
    name: "1 人",
  },
  {
    value: '2',
    name: "2 人",
  },
  {
    value: '3',
    name: "3 人",
  },
  {
    value: '4',
    name: "4 人",
  },
  {
    value: '5',
    name: "5 人",
  },
  {
    value: '6',
    name: "6 人",
  },
  {
    value: '7',
    name: "7 人",
  },
  {
    value: '8',
    name: "8 人",
  },
  {
    value: '9',
    name: "9 人",
  },
  {
    value: '10',
    name: "10 人",
  },
];

export const renderPeopleAmountOptions = () => {
  return peopleAmountOptions.map((optionObj, idx) => 
    <option key={`${optionObj.value}-${idx}`} value={optionObj.value}>{optionObj.name}</option>
  );
};

export const priceOptions = [
  {
    value: '',
    name: "不限",
  },
  {
    value: '400',
    name: "$400以內",
  },
  {
    value: '600',
    name: "$400~$600",
  },
  {
    value: '800',
    name: "$600~$800",
  },
  {
    value: '1000',
    name: "$800~$1,000",
  },
  {
    value: '2000',
    name: "$1,000以上",
  },
];

export const renderPriceOptions = () => {
  return priceOptions.map((optionObj, idx) => 
    <option key={`${optionObj.name}-${idx}`} value={optionObj.value}>{optionObj.name}</option>
  );
};