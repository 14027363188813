export const getCityPosition = (cityId) => {
  switch (cityId){
    // 台北市
    case '101':
      return { zoom: 12, lat: 25.0521, lng: 121.5440 };
    // 新北市
    case '102':
      return { zoom: 12, lat: 25.0154, lng: 121.4733 };
    // 桃園市
    case '104':
      return { zoom: 11, lat: 24.9894, lng: 121.3134 };
    // 新竹市
    case '105':
      return { zoom: 13, lat: 24.8008, lng: 120.9907 };
    // 台中市
    case '202':
      return { zoom: 11, lat: 24.1649, lng: 120.6739 };
    // 南投縣
    case '204':
      return { zoom: 11, lat: 23.7178, lng: 120.7792 }; 
    // 嘉義市
    case '301':
      return { zoom: 14, lat: 23.4765, lng: 120.4549 };
    // 台南市
    case '303':
      return { zoom: 14, lat: 23.001, lng: 120.2255 };
    // 高雄市
    case '304':
      return { zoom: 12, lat: 22.6530, lng: 120.3032 };
    // 宜蘭縣
    case '107':
      return { zoom: 12, lat: 24.7154, lng: 121.7884 };
    // 花蓮縣
    case '401':
      return { zoom: 12, lat: 23.9815, lng: 121.6057 };
    // 台東縣
    case '402':
      return { zoom: 12, lat: 22.7619, lng: 121.1064 };
    // 全台灣
    default:
      return { zoom: 8, lat: 23.715, lng: 120.910 };
  }
}