export { 
  siteTitle, 
  siteUrl, 
  userPageMetaContent, 
  userPageNotPublicMetaContent, 
  DEFAULT_AVATAR_SRC, 
  DEFAULT_FULL_LOGO_SRC,
  GAME_CARD_IMAGE_PLACEHOLDER_SRC,
  SITE_SOCIAL_BANNER_SRC
} from './wording'
export { preferenceMap, preferenceKeyList } from './preferenceMap';
export { allCityCodeMap, basicCityOptions, renderAvailableCityOptions } from './cityCodeMap';
export { renderPeopleAmountOptions, renderPriceOptions } from './peopleAmountMap';
export { featureCodeMap, featureOptions, difficultyOptionsList, featuresOptionsList } from './featureCodeMap';
export { getCityPosition } from './cityPositionMap';
export { getGameTypeDisplay } from './gameTypeHelper'
export { default as parseQs } from './parseQs';
export { userTitleMap, getTitleOptions} from './userTitleMap';
export { generalAchievementsMap, generalAchievementKeys, generalAchievements } from './achievementsMap';
export { NICE_COMMENT_LENGTH, sameSiteOrigins } from "./parameters";
export { emailReg } from './validator';
export { legalQueryList, legalFullQueryList } from './conditionList';
export { default as copyText } from './copyText';
export { default as readFile } from './readFile';
export { dropFirstLetterZero, convertDuration, getResizedPhotoUrl, getCurrentTimeString } from './stringHandlers';
export { default as trackEvent } from './tracking';
export { getDocumentTitle, getDocumentUrl } from './shareHelper'

export const isProdEnv = process.env.NEXT_PUBLIC_ENV === "PROD";