
import React from 'react';
import { FaCrown } from 'react-icons/fa';
import { DEFAULT_AVATAR_SRC } from 'utils'

const AchievementRow = props => {
  const { acvData, rate, isShowBg = false, count, hasAchieved = false, isClickable = false, children } = props;
  const { title, intro, imageSrc, isNew } = acvData;

  const figure = isShowBg 
    ? rate && parseInt(rate) > 0 ? `${rate}%` : 0
    : count
    ;

  const bgWidth = isShowBg && rate && parseInt(rate) > 0 ? `${rate * 2}%` : 0;

  return (
    <div className={`relative my-3 p-1 flex border-2 border-primary transition-opacity opacity-80 hover:opacity-100 ${isClickable ? "hover:cursor-pointer" :  ""})`}>
      <div className="w-12 min-w-[3rem] md:w-16 md:min-w-[4rem] mr-1 md:mr-2 flex items-center">
        <img 
          style={{ background: `radial-gradient(circle at 50% 50%, #d4e0ef, #aac1df, #95b2d8, #6a93c8, #2b66b1` }}
          src={imageSrc || DEFAULT_AVATAR_SRC} 
        />
      </div>
      <div className="relative flex-grow flex">
        <div className="absolute h-full bg-[rgba(38,96,171,.2)] z-0" style={{ width: bgWidth }} />
        <div className="flex-grow">
          <p className="m-1 mt-2 text-sm md:text-base font-semibold">{title}</p>
          <p className="mx-1 text-xs md:text-sm color-slate-400">{intro}</p>
        </div>
        <div className="flex justify-center items-center flex-col-reverse md:flex-row">
          {children}
          <p className="m-0 w-full min-w-12 font-bold text-right md:min-w-14 text-sm md:text-base">
            {figure}
          </p>
        </div>
      </div>
      {hasAchieved && (
        <>
          <div className="absolute top-0 right-0 w-0 h-0 border-[15px] border-t-green-500 border-r-green-500 border-b-transparent border-l-transparent" />
          <div className="absolute top-0 right-0">
            <span className='text-white'>
              <FaCrown />
            </span>
          </div>
        </>
      )}
    </div>
  )
};
export default AchievementRow;