import axios from 'axios';
import { auth } from './firebase/firebase';

export const wpApiRoute = `https://content.escape.bar/?rest_route=/sections/v2/`;
export const fbApiBase = process.env.NEXT_PUBLIC_API_BASE;

export const LINEBOT_REF_URL = "https://lin.ee/iQWzzaq";

export const stringifyQuery = (query) => {
  // 將預設為0或是空的參數去掉
  if (!query) return ''

  return Object.keys(query).reduce((accuString, item) => {
    if (query[item]) {
      return `${accuString}&${item}=${query[item]}`;
    } else {
      return accuString;
    }
  }, '');
}

const getWpContentById = async (url, id) => {
  const response = await axios.get(`${wpApiRoute}${url}${id}`);
  return response;
};

// const getWpContentByQuery = async (url, query) => {
//   const response = await axios.get(`${wpApiRoute}${url}${stringifyQuery(query)}`);
//   return response;
// };

const requestExpressApi = async (method, url, params = null) => {
  let token = '';

  try {
    if (auth && auth.currentUser) {
      token = await auth.currentUser.getIdToken();
    }

    const configs = {
      method: method,
      url: url,
      baseURL: fbApiBase,
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    if (params) {
      configs.data = params
    }

    const response = await axios.request(configs);
    
    return response;
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

// data from CMS
// export const getGamesTitleSuggestion = (query) => getWpContentByQuery("games/title", query);
// export const getStoresList = (query) => getWpContentByQuery('stores', query);
// export const getPostsList = (tagId) => getWpContentById(`posts/`, tagId);
// export const getPostDetail = ({postType, postSlug}) => getWpContentById(`post/${postType}/`, postSlug);
// export const getAvailableTodayStoresList = () => getWpContentById('stores/availabletodaylist', '');

// CMS data from BFF
export const getGlobalData = () => requestExpressApi('get', 'static/global');
export const getHomeData = () => requestExpressApi('get', 'static/home');
export const getGamesList = (query) => requestExpressApi('get', `static/games/list?${stringifyQuery(query)}`);
export const getGamesBasicInfo = (param) => requestExpressApi('post', 'static/games/basic', param);
export const getGameDetail = (gameId) => requestExpressApi('get', `static/game/${gameId}`);
export const getTitleMap = () => requestExpressApi("get", `static/games/title`);
export const getGamesTitleSuggestion = q => requestExpressApi("get", `static/games/title-suggestion?q=${q}`);
export const getFirmsList = (query) => requestExpressApi('get', `static/firms/list?${stringifyQuery(query)}`);
export const getFirmsBasicInfo = (param) => requestExpressApi('post', 'static/firms/basic', param);
export const getFirmDetail = (firmId) => requestExpressApi('get', `static/firm/${firmId}`);
export const getStoresList = () => requestExpressApi('get', `static/stores/list`)
export const getStoreGamesList = (storeId) => requestExpressApi('get', `static/store/${storeId}`);
export const getPlaceCandidates = query => requestExpressApi('get', `bridge/place?${stringifyQuery(query)}`);

// Review
export const getOneReview = (gameId) => requestExpressApi("get", `review/get-one/${gameId}`);
export const getGameReviews = (query) => requestExpressApi("get", `review/get-by-game?${stringifyQuery(query)}`);
export const getUserReviews = () => requestExpressApi("get", "review/get-by-user");

export const getUserJourney = (param) => requestExpressApi("post", "review/get-user-journey", param);

export const createReview = (param) => requestExpressApi("post", "review/create", param);
export const updateReview = (param) => requestExpressApi("put", "review/edit", param);
export const deleteReview = (param) => requestExpressApi("delete", "review/delete", param);
export const postReviewAbuse = (param) => requestExpressApi("post", "review/abuse", param);
export const postReviewFeedback = (param) => requestExpressApi("post", "review/feedback", param);

// User
export const createNewUser = (param) => requestExpressApi("post", "user/create", param);
export const getUserInfo = (param) => requestExpressApi("get", "user/get-user-info", param);
export const updateUserEmail = (param) => requestExpressApi("put", "user/update-email", param);
export const postLike = (param) => requestExpressApi("post", "collect/like/push", param);
export const postFollow = (param) => requestExpressApi("post", "collect/follow/push", param);

export const getUserPageIsPublic = (customId) => requestExpressApi("get", `user/check-is-public/${customId}`);
export const getUserProfile = (customId) => requestExpressApi("get", `user/get-user-profile/${customId}`);
export const postUserInfo = (param) => requestExpressApi("post", "user/edit", param);
export const retrieveLinkingNonce = (param) => requestExpressApi("post", "user/linking/retrieve", param);
export const unconnectLinkingLine = (param) => requestExpressApi("put", "user/linking/unconnect", param);

export const checkNotification = query => requestExpressApi("get", `notification/new?${stringifyQuery(query)}`);
export const getNotifications = query => requestExpressApi("get", `notification/list?${stringifyQuery(query)}`);
export const readNotification = id => requestExpressApi("get", `notification/read/${id}`);

// Achievement
export const getAchivements = params => requestExpressApi("get", `achievement/list`, params);
export const attendChallenge = challengeId => requestExpressApi("get", `achievement/attend/${challengeId}`);
export const validateChallenge = params => requestExpressApi("post", `achievement/validate`, params);
export const getAchievedRatio = customId => requestExpressApi("get", `achievement/achieved-ratio/${customId}`);

export const postSuggestion = (param) => requestExpressApi("post", "review/suggestion", param);

// Event GOTY2023
export const getPollStat = () => requestExpressApi("get", "event/awards/goty2023");
export const getVoteStatus = (param) => requestExpressApi("post", "event/awards/goty2023/status", param);
export const postVote = (param) => requestExpressApi("post", "event/awards/goty2023/vote", param);
export const postJoin = (param) => requestExpressApi("post", "event/awards/goty2023/join", param);
export const getPtp = () => requestExpressApi("post", "event/awards/goty2023/get-ptp");
export const postPtp = (param) => requestExpressApi("post", "event/awards/goty2023/validate-ptp", param);

// Event Lantern2025
export const getLanternStatus = () => requestExpressApi("get", "event/puzzles/lantern2025/status");
export const getLanternPuzzle = (query) => requestExpressApi("get", `event/puzzles/lantern2025/puzzle?${stringifyQuery(query)}`);
export const validateLanternAnswer = (param) => requestExpressApi("post", "event/puzzles/lantern2025/validate", param);
export const getLanternResult = () => requestExpressApi("get", "event/puzzles/lantern2025/result");