export function dropFirstLetterZero (str) {
  if(str.charAt(0) == 0){
      return str.substring(1);
  } else {
      return str;
  }
}

export function convertDuration(duration) {
  // 取前3位數字，用轉成數字的方式去掉第一碼0。
  let duration3digits = parseInt(duration.substring(0,3));

  if(duration3digits > 120){
    return ">120";
  } else {
    return duration3digits;
  }
}

export function getResizedPhotoUrl(url) {
  if (url === "") {
    return ""
  }

  let fileType = ''

  if (url.includes('.png')) {
    fileType = '.png';
  } else if (url.includes('.jpg')) {
    fileType = '.jpg';
  } else if (url.includes('.jpeg')) {
    fileType = '.jpeg';
  }

  const splitOriginalURLArray = url.split(fileType);
  const splitQueryArray = splitOriginalURLArray[1].split('&token=');

  return `${splitOriginalURLArray[0]}_600x600${fileType}${splitQueryArray[0]}`
};

export function getCurrentTimeString (timeObject) {
  return `${timeObject.getFullYear()}/${timeObject.getMonth() + 1}/${timeObject.getDate()} ${timeObject.getHours()}:${timeObject.getMinutes()}`
};