import React, { useRef } from 'react';
import { useRouter } from 'next/navigation'
import * as Sentry from "@sentry/nextjs";
import { useState, useEffect, createContext, useContext } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import { getGlobalData } from '../api';
import { trackEvent } from 'utils';

const DISPLAY_STANDALONE = '(display-mode: standalone)';

export const GlobalContext = createContext()

export default function GlobalContextComp({ children }) {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [coverData, setCoverData] = useState(null);
  const [isScreenLg] = useMediaQuery('(min-width: 1024px)')
  const [isStandalone, setIsStandalone] = useState(false);

  const deferredPromptRef = useRef(null);

  const router = useRouter();

  useEffect(() => {
    detectIsStandalone()
    fetchGlobalData();

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      deferredPromptRef.current = e;

      console.log(`>> beforeinstallprompt event catched!`)
    });
  }, [])

  const detectIsStandalone = () => {
    if (navigator.standalone || window.matchMedia(DISPLAY_STANDALONE).matches) {
      trackEvent({
        category: "Start_in_standalone", 
        action: "Enter",
      })

      setIsStandalone(true);
    }
  };

  // const judgeIsWebview = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //   const webviewRegex = /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv|.0.0.0))/gi;

  //   return webviewRegex.test(userAgent) 
  //     || (userAgent.indexOf("FBAN") > -1) 
  //     || (userAgent.indexOf("FBAV") > -1) 
  //     || (userAgent.indexOf("Line") > -1)
  //     ;
  // }

  const fetchGlobalData = async () => {
    try {
      const res = await getGlobalData();

      if (res?.data?.cover_v2) {
        const currentTime = Date.now() / 1000;

        const availableColumns = res.data.cover_v2
          .filter(col => {
            // 先過濾有效期間內的廣告
            const { start_time, end_time } = col.column;
            
            return currentTime >= parseInt(start_time) && currentTime <= parseInt(end_time)
          }).map(col => col.column);

        setCoverData(availableColumns);
      }
    } catch (err) {
      console.log(err)
      Sentry.captureException(err);
      // 若此處有 error 理論上全站都會有問題，配合 PWA 轉導至 /offline 
      router.push("/offline");
    }
  };

  const handleLoginOpen = () => {
    setIsLoginOpen(true);
  };

  const handleLoginClose = () => {
    setIsLoginOpen(false);
  };

  const handleLogoutOpen = () => {
    setIsLogoutOpen(true);
  };

  const handleLogoutClose = () => {
    setIsLogoutOpen(false);
  };

  const handleInstallPrompt = () => {
    if (deferredPromptRef.current === null) {
      router.push(`/post/download-escape-bar-progressive-web-app`)
    };

    deferredPromptRef.current.prompt();

    deferredPromptRef.current.userChoice.then((choiceResult) => {

      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }

      deferredPromptRef.current = null;
    });
  };

  return (
    <GlobalContext.Provider value={{ 
      isLoginOpen,
      isLogoutOpen,
      coverData,
      isScreenLg,
      isStandalone,
      onOpenLogin: handleLoginOpen,
      onCloseLogin: handleLoginClose,
      onOpenLogout: handleLogoutOpen,
      onCloseLogout: handleLogoutClose,
      onInstall: handleInstallPrompt,
    }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => useContext(GlobalContext)