export const featureCodeMap = {
  feature_1 : '新手入門',
  feature_2 : '中度玩家',
  feature_3 : '重度解謎',
  feature_4 : '偵探推理',
  feature_5 : '機關重重',
  feature_6 : '劇情厲害',
  feature_7 : '場景逼真',
  feature_8 : '輕鬆歡樂',
  feature_9 : '恐怖驚悚',
  feature_10 : '緊張刺激',
  feature_11 : '勾心鬥角',
  feature_12 : '團隊合作',
  feature_13 : '親子同遊',
  feature_14 : '玩法特別',
  feature_15 : '互動操作',
  feature_16 : '謎題邏輯',
  feature_17 : '角色扮演',
  isOutdoorGame: '實境解謎',
  isBigGame: '大型團體',
  isGameOver: '已謝幕'
};

export const featureOptions = [
  {
    value: '',
    name: '不限',
  },
  {
    value: '1',
    name: '新手入門',
  },
  {
    value: '2',
    name: '中度玩家',
  },
  {
    value: '3',
    name: '重度解謎',
  },
  {
    value: '4',
    name: '偵探推理',
  },
  {
    value: '5',
    name: '機關重重',
  },
  {
    value: '6',
    name: '劇情厲害',
  },
  {
    value: '7',
    name: '場景逼真',
  },
  {
    value: '15',
    name: '互動操作',
  },
  {
    value: '16',
    name: '謎題邏輯',
  },
  {
    value: '8',
    name: '輕鬆歡樂',
  },
  {
    value: '9',
    name: '恐怖驚悚',
  },
  {
    value: '10',
    name: '緊張刺激',
  },
  {
    value: '11',
    name: '勾心鬥角',
  },
  {
    value: '12',
    name: '團隊合作',
  },
  {
    value: '13',
    name: '親子同遊',
  },
  {
    value: '14',
    name: '玩法特殊',
  },
  {
    value: '17',
    name: '角色扮演',
  }
];

export const difficultyOptionsList = featureOptions.slice(1, 4);
export const featuresOptionsList = featureOptions.slice(4);