function getGameTypeDisplay ({ 
  isGameOver = false, 
  isOutdoorGame = false, 
  isScenarioGame = false, 
  isTheaterGame = false, 
  isBigGame = false,
  inShort = false,
}) {
  let result = {
    typeColor: 'blue',
    typeLabel: '密室逃脫'
  }

  if (isGameOver) {
    result = {
      typeColor: 'gray',
      typeLabel: '已謝幕'
    }
  } else if (isBigGame) {
    result = {
      typeColor: 'pink',
      typeLabel: '大型團體遊戲'
    }
  } else if (isScenarioGame) {
    result = {
      typeColor: 'green',
      typeLabel: '角色扮演遊戲'
    }
  } else if (isTheaterGame) {
    result = {
      typeColor: 'purple',
      typeLabel: '沈浸劇場遊戲'
    }
  } else if (isOutdoorGame) {
    result = {
      typeColor: 'yellow',
      typeLabel: '實境解謎遊戲'
    }
  }

  if (inShort) {
    result.typeLabel = result.typeLabel.substring(0, 4)
  }

  return result;
};

export { getGameTypeDisplay }