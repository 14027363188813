import qs from 'query-string';

export const parseQs = data => qs.parse(data, {
  decoder(value) {
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
          return parseFloat(value);
      }

      let keywords = {
          true: true,
          false: false,
          null: null,
          undefined: undefined,
      };
      if (value in keywords) {
          return keywords[value];
      }

      return value;
  }
});

export default parseQs;