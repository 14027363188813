import { useState, useEffect, useMemo, createContext, useContext } from 'react'
import * as Sentry from "@sentry/nextjs";
import qs from 'query-string';
import { useToast } from '@chakra-ui/react'
import { ebFirebase, auth, createProvider } from '../firebase/firebase';
import { getUserInfo, createNewUser, postUserInfo, postLike, postFollow, updateUserEmail } from '../api';
import { LOCAL_STORAGE_NOTIFICATION_ID_KEY, LOCAL_STORAGE_NOTIFICATION_CACHE } from '../components/common/header/NotificationCenter'

const LOCAL_STORAGE_USER_CUSTOM_ID_KEY = "customId";

const initialUserState = {
  userId: "",
  customId: "",
  email: "",
  displayName: "",
  photoUrl: "",
  title: "",
  titleText: "",
  followingFirmsIdList: [],
  likeGamesIdList: [],
  reviewedGamesIdList: [],
  specialTitleList: [],
  hasLinkedLine: false,
  achievements: [],
  challenges: {}
};

export const UserContext = createContext()

const TOAST_DURATION = 2000;

export default function UserContextComp({ children }) {
  const [hasAuthRedirectedResult, setHasAuthRedirectedResult] = useState(false);
  const [user, setUser] = useState(initialUserState)
  const [newUser, setNewUser] = useState(null);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isPostingLogin, setIsPostingLogin] = useState(false); // 三者應該可簡化？
  const [isPostingLogout, setIsPostingLogout] = useState(false);
  const [error, setError] = useState(null);

  const toast = useToast()

  useEffect(() => {
    // 判斷是否有邀請碼，若有的話存起來，稍後用於註冊
    const queryString = qs.parse(window.location.search);
    if (queryString.ivtId || queryString.ivtType) {
      window.sessionStorage.setItem('inviterId', queryString.ivtId || '');
      window.sessionStorage.setItem('inviterType', queryString.ivtType || '');
    };

    getAuthRedirectResult();
  }, [])

  useEffect(() => {
    let unsubscriber = () => {};

    if (hasAuthRedirectedResult) {
      unsubscriber = auth.onAuthStateChanged(async (user) => {
        try {
          if (user !== null) {
            const userInfo = await getUserInfo();
            if (userInfo.data.hasOwnProperty('email')) {
              setUser({
                ...initialUserState,
                userId: user.uid,
                ...userInfo.data
              })
            }

            if (typeof window !== "undefined") {
              window.localStorage.setItem(LOCAL_STORAGE_USER_CUSTOM_ID_KEY, userInfo.data.customId);
            }
          }
        } catch (error) {
          // Most probably a connection error. Handle appropriately.
          errorHanlder(error);
        } finally {
          setIsLoadingUser(false);
        }
      })
    }

    // Unsubscribe auth listener on unmount
    return () => unsubscriber()
  }, [hasAuthRedirectedResult]);

  const isLogin = useMemo(() => {
    return !!user.email
  }, [user]);

  const isNewRegUser = useMemo(() => {
    return newUser !== null;
  }, [newUser]);

  const handleLogin = async authProvider => {
    const provider = createProvider(authProvider);
  
    setIsPostingLogin(true);
  
    try {
      await auth.signInWithRedirect(provider);
    } catch (err) {
      errorHanlder(err);
    } finally {
      setIsPostingLogin(false);
    }
  };

  const handleLogout = async () => {
    setIsPostingLogout(true);

    try {
      window.localStorage.removeItem(LOCAL_STORAGE_USER_CUSTOM_ID_KEY);
      window.localStorage.removeItem(LOCAL_STORAGE_NOTIFICATION_ID_KEY)
      window.localStorage.removeItem(LOCAL_STORAGE_NOTIFICATION_CACHE)

      await auth.signOut();
      window.location.reload();
    } catch (err) {
      errorHanlder(err);
    }
  };

  const getAuthRedirectResult = async () => {
    let inviter = null;

    try {
      const authUserResult = await auth.getRedirectResult();

      if (authUserResult.user === null) {
        setHasAuthRedirectedResult(true);
        return;
      }

      const inviterId = window.sessionStorage.getItem('inviterId');
      const inviterType = window.sessionStorage.getItem('inviterType');

      if (inviterId && inviterType) {
        inviter = {
          inviterId,
          inviterType
        }
      }

      setIsPostingLogin(true);
      
      const {
        user: { uid, email, photoURL, displayName },
        additionalUserInfo: {
          isNewUser,
          providerId,
          profile: {
            given_name, family_name, // Google
            first_name, last_name, // Facebook
          },
        },
      } = authUserResult;

      const authProvider = providerId === 'google.com' ? 'google' : 'facebook';
      const isProvierGoogle = authProvider === 'google';

      // Handle is new user or not
      if (isNewUser === true) {
        // Facebook 可能會沒有 Email ，須先檢查
        if (!email) {
          throw {
            code: "email-empty"
          }
        }

        // 若為 isNewUser ，則在 fireStore 用 uid 新建 user ，把 additionalUserInfo 塞進去，並且將初次取得資料送給 UI
        const userData = {
          userId: uid,
          email,
          displayName,
          photoUrl: photoURL,
          firstName: first_name || given_name,
          lastName: last_name || family_name,
          provider: [authProvider],
          inviter,
        };

        const res = await createNewUser(userData);

        const { hasNewUserCreated } = res.data;

        if (hasNewUserCreated) {
          setNewUser({
            isShouldCheckEmail: !isProvierGoogle,
            email,
            userId: uid,
          })
        }
      }

      window.localStorage.setItem("PREVIOUS_LOGIN_PROVIDER", isProvierGoogle ? "Google" : "Facebook");
    } catch (err) {
      console.log(">> CATCH ERROR IN getAuthRedirectResult")

      if (err && err.code === 'auth/account-exists-with-different-credential') {
        const prevProvider = await auth.fetchSignInMethodsForEmail(err.email);
        errorHanlder({
          prevProvider: prevProvider[0], 
          ...err
        });
      } else {
        errorHanlder(err);
      }
    } finally {
      setHasAuthRedirectedResult(true);
      setIsPostingLogin(false);
    }
  };

  const regetUserInfo = async () => {
    if (!user.userId) return ;

    const userInfo = await getUserInfo();

    setUser({
      ...user,
      ...userInfo.data
    })
  };

  const updateEmail = async ({ newEmail }) => { 
    setIsPostingLogin(true);

    try {
      const res = await updateUserEmail({ newEmail });
      return res.data;
    } catch (err) {
      errorHanlder(err);
    } finally {
      setIsPostingLogin(false);
    }
  };

  const updateList = async (type, targetId, params) => {
    let targetListKey, newList = [], hasStatus;

    if (user.userId === "") return;

    switch (type) {
      case 'review':
        targetListKey = "reviewedGamesIdList";
        break;
      case 'like':
        targetListKey = "likeGamesIdList"
        break;
      case 'follow':
        targetListKey = "followingFirmsIdList";
        break;
    }

    if (!targetListKey) return;


    if (user[targetListKey].indexOf(targetId) > -1) {
      newList = user[targetListKey].filter(id => id !== targetId);
      hasStatus = true;
    } else {
      newList = [...user[targetListKey], targetId];
      hasStatus = false;
    }

    setUser({
      ...user,
      [targetListKey]: newList
    })

    // call API here
    try {
      if (type === 'review') {
        // 加入與移出 list 會在背景 cloud function 執行，此處不必做事
      } else if (type === "like") {
        await postLike({
          gameId: targetId,
          type: hasStatus ? "UNLIKE" : "LIKE",
        });

        toast({
          title: hasStatus ? "已移除收藏" : "已加入收藏清單",
          status: 'success',
          position: 'top',
          duration: TOAST_DURATION,
        })
      } else if (type === "follow") {
        await postFollow({
          firmId: targetId,
          type: hasStatus ? "UNFOLLOW" : "FOLLOW",
        })

        toast({
          title: hasStatus ? "已取消追蹤" : "已加入追蹤清單",
          status: 'success',
          position: 'top',
          duration: TOAST_DURATION,
        })
      }
    } catch (err) {
      toast({
        title: "無法順利完成，請稍後再試。",
        status: 'error',
        position: 'top',
        duration: TOAST_DURATION,
      })

      errorHanlder(err);
    }
  };

  const updatePhotoUrl = async (url) => {
    try {
      await postUserInfo({
        form: {
          photoUrl: url
        }
      })
    } catch (err) {
      errorHanlder(err);
    }
  };

  const setErrorClear = () => {
    setError(null);
    setIsLoadingUser(false);
  };

  const errorHanlder = err => {
    console.debug(err);
    Sentry.captureException(err);
    setError(err);
  };

  // 在 isNewUser 或是有 error 時會透過 Header 開啟 LoginDialog
  return (
    <UserContext.Provider 
      value={{ 
        isLogin,
        isLoadingUser, 
        isPostingLogin,
        isPostingLogout,
        isNewUser: isNewRegUser, 
        user, 
        newUser,
        hasNewNotification, 
        error,
        setLogin: handleLogin,
        setLogout: handleLogout,
        regetUserInfo,
        updateEmail,
        updateList,
        updatePhotoUrl,
        setHasNewNotification,
        setErrorClear,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)