export const allCityCodeMap = {
  10 : "北部",
  101 : "台北市",
  102 : "新北市",
  103 : "基隆市",
  104 : "桃園市",
  105 : "新竹市",
  106 : "新竹縣",
  107 : "宜蘭縣",
  20 : "中部",
  201 : "苗栗縣",
  202 : "台中市",
  203 : "彰化縣",
  204 : "南投縣",
  205 : "雲林縣",
  30 : "南部",
  301 : "嘉義市",
  302 : "嘉義縣",
  303 : "台南市",
  304 : "高雄市",
  305 : "屏東縣",
  40: "東部",
  401 : "花蓮縣",
  402 : "台東縣",
  500 : "離島",
  600 : "實境解謎",
  999 : "全台灣"
};

export const availableCityOptions = [
  {
    value: '',
    name: "選擇城市",
  },
  {
    value: '101,102',
    name: "大台北",
  },
  {
    value: '101',
    name: "台北市",
  },
  {
    value: '102',
    name: "新北市",
  },
  {
    value: '103',
    name: "基隆市",
  },
  {
    value: '104',
    name: "桃園市",
  },
  {
    value: '105',
    name: "新竹市",
  },
  {
    value: '201',
    name: "苗栗縣",
  },
  {
    value: '202',
    name: "台中市",
  },
  {
    value: '203',
    name: "彰化縣",
  },
  {
    value: '204',
    name: "南投縣",
  },
  {
    value: '205',
    name: "雲林縣",
  },
  {
    value: '301',
    name: "嘉義市",
  },
  {
    value: '302',
    name: "嘉義縣",
  },
  {
    value: '303',
    name: "台南市",
  },
  {
    value: '304',
    name: "高雄市",
  },
  {
    value: '305',
    name: "屏東縣",
  },
  {
    value: '107',
    name: "宜蘭縣",
  },
  {
    value: '401',
    name: "花蓮縣",
  },
  {
    value: '402',
    name: "台東縣",
  },
  {
    value: '500',
    name: "澎湖金門馬祖"
  },
  {
    value: '999',
    name: "全台灣",
  },
];

export const basicCityOptions = availableCityOptions.filter(optionObj => 
  optionObj.value !== "" && optionObj.value !== "101,102" && optionObj.value !== "999"
);

/**
 * @param {boolean} allCities 若為 true ，第一選項為"全台灣"，否則為"請選擇"，目前僅適用用地圖找
 * @param {boolean} twinTaipei 若為 true ，則選項會有 "大台北"，目前僅適用找遊戲
 */
export const renderAvailableCityOptions = (props = {}) => {
  const { allCities, twinTaipei } = props;

  return availableCityOptions
    .filter(optionObj => {
      switch(optionObj.value) {
        case '101,102':
          return twinTaipei ?? false;
        case '999':
          return allCities ?? false;
        default:
          return true;
      }
    })
    .map((optionObj, idx) => 
      <option 
        key={`${optionObj.value}-${idx}`} 
        value={optionObj.value}
      >
        {optionObj.name}
      </option>
    )
}
