import { useState, useMemo, useEffect, useRef } from 'react';
import { useRouter } from 'next/navigation';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  HStack,
  Center,
  Tag,
  TagLeftIcon,
  TagLabel,
  Skeleton
} from '@chakra-ui/react'
import { AiOutlineCheck } from 'react-icons/ai';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { getNotifications, readNotification, checkNotification } from "src/api";
import { useUser } from 'src/context';

const yesterday = dayjs().subtract(1, 'day');

// TODO: pagination and load more

// 1. 登入完成取 getUserInfo 後，也確認是否有新通知。如果 localStorage 沒有最後一則的 id ，就是確認是否有任何未讀通知
// 2. 常態進行 polling API 問是否有新通知，如果有的話則小鈴鐺顯示有新通知
// 3. 開啟此元件時，如果 localStorage 沒有最後一則的 id 或者沒有 cache，就是取最新的 N 則，並且將最後一則的 id 以及 cache 存到 localStorage
// 4. 持續 polling API ，以 localStorage 的 id 為參數，問是否有晚於最後一則的新通知，若有新則小鈴鐺顯示有新通知
// 5. 開啟此元件時，如果 localStorage 有最後一則的 id，以此 id 為參數取晚於此時間的通知，並結合既有的 cache 與最後一則的 id 存到 localStorage
// 6. 下次登入後取 getUserInfo 後，如果 localStorage 有最後一則的 id，則以此 id 為參數確認是否有新的通知
// * 登出時會將 localStoage 的 cache 以及 id 清空

export const LOCAL_STORAGE_NOTIFICATION_ID_KEY = "lastNotificationId"
export const LOCAL_STORAGE_NOTIFICATION_CACHE = "notificationsCache"
const POLLING_INTERVAL = 60 * 1000 * 6;

function getTypeText (type) {
  switch (type) {
    case 'NEWS':
      return '快訊'
    case 'EVENT':
      return '活動'
    case 'ACHIEVEMENT':
      return '成就'
    case 'SOCIAL':
      return '社群'
    case 'SHOP':
      return '商城'
    case 'SYSTEM':
      return '系統'
    default:
      return '其它'
  };
};

function getTypeColor (type) {
  switch (type) {
    case 'NEWS':
      return 'green'
    case 'EVENT':
      return 'orange'
    case 'ACHIEVEMENT':
      return 'red'
    case 'SOCIAL':
      return 'purple'
    case 'SHOP':
      return 'yellow'
    case 'SYSTEM':
    default:
      return 'primary'
  };
}

const NotificationCenter = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterUnread, setIsFilterUnread] = useState(false);
  const [data, setData] = useState([]);

  const { isLogin, setHasNewNotification } = useUser();

  const isPolling = useRef(false);
  const pollingTimer = useRef(null);

  const notifications = useMemo(() => {
    if (isFilterUnread) {
      return data.filter(n => !n.hasRead);
    } else {
      return data
    }
  }, [data, isFilterUnread]);

  useEffect(() => {
    return () => {
      isPolling.current = false;
      pollingTimer.current = null;
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isLogin) {
      if (pollingTimer.current === null && isPolling.current === false) { 
        isPolling.current = true;
        setInterval(() => {
          pollingTimer.current = fetchNewNotification()
        }, POLLING_INTERVAL);
      }
    } else {
      isPolling.current = false;
      pollingTimer.current = null;
    }
  }, [isLogin]);


  const fetchNewNotification = async () => {
    const lastNotificationId = window.localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_ID_KEY);
    const payload = {}

    try {
      if (lastNotificationId) {
        payload.latest = lastNotificationId;
      }

      const res = await checkNotification(payload);

      if (res.data.hasUnread) {
        setHasNewNotification(true);
      }
    } catch (err) {
      console.log(err)
    }
  };

  const fetchNotifications = async () => {
    const cache = window.localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_CACHE);
    const parsedCache = JSON.parse(cache)
    const lastNotificationId = window.localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_ID_KEY);

    const payload = {}
    let newData = []

    setIsLoading(true);

    try {
      if (parsedCache?.length > 0 && lastNotificationId) {
        payload.latest = lastNotificationId;
        newData = parsedCache;
      }

      const res = await getNotifications(payload)

      if (res.data.result?.length > 0) {
        // 有新資料
        const result = res.data.result;

        newData = [...result, ...newData];

        setData(newData);

        const lastId = result[0]._id
        window.localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_ID_KEY, lastId);
        window.localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_CACHE, JSON.stringify(newData));
      } else {
        // 沒有新資料，如果 local storage cache 有就塞入
        setData(newData)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false);
    }
  }

  const handleCacheRead = (id) => {
    // 更新 cache 該條 notification 為已讀
    const newData = data.map(notification => {
      if (notification._id === id) {
        return {
          ...notification,
          hasRead: true
        }
      } else {
        return notification
      }
    });

    window.localStorage.removeItem(LOCAL_STORAGE_NOTIFICATION_CACHE);
    window.localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_CACHE, JSON.stringify(newData));

    setData(newData);
  };

  return (
    <Drawer 
      size='md'
      placement='right' 
      onClose={onClose} 
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader 
          p={3} 
          borderBottomWidth='1px'
        >
          通知中心
          <HStack mt={2}>
            <Tag 
              borderRadius='full'
              variant={!isFilterUnread ? 'solid' : 'outline'}
              colorScheme='primary'
              _hover={{ cursor: !isFilterUnread ? 'default' : 'pointer' }}
              onClick={() => setIsFilterUnread(false)}
            >
              {!isFilterUnread && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
              <TagLabel>全部</TagLabel>
            </Tag>
            <Tag 
              borderRadius='full'
              variant={isFilterUnread ? 'solid' : 'outline'}
              colorScheme='secondary'
              _hover={{ cursor: isFilterUnread ? 'default' : 'pointer' }}
              onClick={() => setIsFilterUnread(true)}
            >
              {isFilterUnread && <TagLeftIcon boxSize={4} as={AiOutlineCheck} />}
              <TagLabel>未讀</TagLabel>
            </Tag>
          </HStack>
        </DrawerHeader>
        <DrawerBody p={0}>
          {isLoading && <SkeletonGroup />}

          {notifications.map((data) => 
            <NotificationRow 
              key={`notification-${data._id}`} 
              {...data} 
              onClose={onClose}
              onRead={handleCacheRead}
            />
          )}
          {!isLoading && (
            <Center mt={2}>
              <p className='font-bold'>{notifications.length > 0 ? "沒有更多通知囉" : "目前沒有通知"}</p>
            </Center>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
};

const NotificationRow = (props) => {
  const { _id, type, title, content, createdAt, link, hasRead, onClose, onRead } = props;

  const createTime = dayjs(createdAt);
  const displayDateTime = createTime.isAfter(yesterday) ? createTime.format("HH:mm") : createTime.format("YYYY-MM-DD")

  const router = useRouter();

  const handleClick = async () => {
    try {
      if (link?.length > 0) {
        router.push(link)
        onClose()
      }
      
      onRead(_id)

      await readNotification(_id)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack 
      px={3}
      py={2}
      spacing={1}
      borderBottomWidth='1px'
      _hover={{
        bg: 'gray.100',
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <HStack>
        <Tag colorScheme={getTypeColor(type)}>
          {getTypeText(type)}
        </Tag>
        <p className='font-bold'>{title}</p>
      </HStack>
      <p className='text-sm'>{content}</p>
      <HStack justify='space-between'>
        <p className='text-xs text-primary font-semibold'>{displayDateTime}</p>
        <div className={clsx("w-2 h-2 rounded-full", { 'bg-secondary': !hasRead } )} />
      </HStack>
    </Stack>
  )
};

const SkeletonRow = () => {
  return (
    <Stack p={3} spacing={1} borderBottomWidth='1px'>
      <Skeleton width='180px' height='20px' />
      <Skeleton height='16px' />
      <Skeleton width='100px' height='16px' />
    </Stack>
    )
}

const SkeletonGroup = () => {
  return(
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  )
};

export default NotificationCenter