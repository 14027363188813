import { generalAchievementsMap, generalAchievementKeys } from './';
import { CHALLENGE_STATUS } from 'src/components/pages/AchievementsPage';

export const userTitleMap = {
  none: {
    value: 'none',
    fullDisplayText: '',
    shortDisplayText: '',
    minimumScore: 0,
    isSpecial: false,
  },
  newbie: {
    value: 'newbie',
    fullDisplayText: '剛學會開鎖的初心者',
    shortDisplayText: '初心者',
    minimumScore: 55,
    isSpecial: false,
  },
  adventurer: {
    value: 'adventurer',
    fullDisplayText: '偶爾求救的冒險者',
    shortDisplayText: '冒險者',
    minimumScore: 80,
    isSpecial: false,
  },
  player: {
    value: 'player',
    fullDisplayText: '很少卡關的闖關者',
    shortDisplayText: '闖關者',
    minimumScore: 105,
    isSpecial: false,
  },
  escaper: {
    value: 'escaper',
    fullDisplayText: '靠直覺就能過關的逃脫者',
    shortDisplayText: '逃脫者',
    minimumScore: 205,
    isSpecial: false,
  },
  senior: {
    value: 'senior',
    fullDisplayText: '還沒發揮實力就過關的資深玩家',
    shortDisplayText: '資深玩家',
    minimumScore: 305,
    isSpecial: false,
  },
  zealot: {
    value: 'zealot',
    fullDisplayText: '永遠都有下一場密室逃脫的狂熱者',
    shortDisplayText: '狂熱者',
    minimumScore: 605,
    isSpecial: false,
  },
  luckyPerson: {
    value: 'luckyPerson',
    fullDisplayText: '手氣不錯的幸運星',
    shortDisplayText: '幸運星',
    minimumScore: 0,
    isSpecial: true,
  },
  archaeologist: {
    value: 'archaeologist',
    fullDisplayText: '我的成長史就是密室的發展史',
    shortDisplayText: '考古學家',
    minimumScore: 0,
    isSpecial: true,
  },
  phitaophi2023: {
    value: 'phitaophi2023',
    fullDisplayText: '在第二屆金逃獎中成功尋獲大秘寶的斐逃斐榮譽會員',
    shortDisplayText: '斐逃斐榮譽會員：2023',
    minimumScore: 0,
    isSpecial: true,
  },
  phitaophi2024_integrator: {
    value: 'phitaophi2024_integrator',
    fullDisplayText: '在第三屆金逃獎中參與西方陣營並完成年度入會考核的斐逃斐榮譽會員',
    shortDisplayText: '斐逃斐榮譽會員：整合者',
    minimumScore: 0,
    isSpecial: true,
  },
  phitaophi2024_pioneer: {
    value: 'phitaophi2024_pioneer',
    fullDisplayText: '在第三屆金逃獎中參與東方陣營並完成年度入會考核的斐逃斐榮譽會員',
    shortDisplayText: '斐逃斐榮譽會員：開拓者',
    minimumScore: 0,
    isSpecial: true,
  },
  phitaophi2024_guardian: {
    value: 'phitaophi2024_guardian',
    fullDisplayText: '在第三屆金逃獎中參與中立陣營並完成年度入會考核的斐逃斐榮譽會員',
    shortDisplayText: '斐逃斐榮譽會員：守護者',
    minimumScore: 0,
    isSpecial: true,
  },
  lantern2025_junior: {
    value: 'lantern2025_junior',
    fullDisplayText: '在金蛇年燈謎大會中答對了十題燈謎的潛力新秀',
    shortDisplayText: '金蛇燈謎：小有所成',
    minimumScore: 0,
    isSpecial: true,
  },
  lantern2025_senior: {
    value: 'lantern2025_senior',
    fullDisplayText: '在金蛇年燈謎大會中答對了三十題燈謎的明日之星',
    shortDisplayText: '金蛇燈謎：登堂入室',
    minimumScore: 0,
    isSpecial: true,
  },
  lantern2025_master: {
    value: 'lantern2025_master',
    fullDisplayText: '在金蛇年燈謎大會中答對了五十題燈謎的學識大家',
    shortDisplayText: '金蛇燈謎：博古通今',
    minimumScore: 0,
    isSpecial: true,
  },
};

const specialTitleKeys = Object.keys(userTitleMap).filter(key => userTitleMap[key].isSpecial);

/**
 * @param    {string} currentTitle           user 當下使用的 title 
 * @param    {object} achievements           
 * @param    {object} challengesData             
 * @param    {array}  specialTitleList       user 可使用的特殊稱號，應該可 DEPRECATE
 */

export const getTitleOptions = ({ currentTitle = "", achievements = [], challengesData = {}, specialTitleList = [] }) => {
  let availableTitleList = [""];

  if (currentTitle) {
    availableTitleList.push(currentTitle)
  }
  if (achievements.length > 0) {
    achievements.forEach(acvKey => {
      if (specialTitleKeys.includes(acvKey)) {
        // 2024 年中以後，特殊稱號也放在 achievements 中
        availableTitleList.push(userTitleMap[acvKey].shortDisplayText)
      } else if (generalAchievementKeys.includes(acvKey)) {
        availableTitleList.push(generalAchievementsMap[acvKey].title)
      } else if (challengesData[acvKey]) {
        availableTitleList.push(challengesData[acvKey].title)
      }
    });
  }
  // 2024 年中以後，後端會把特殊稱號也放在 achievements 中，此段應該可以省略
  // if (specialTitleList.length > 0) {
    // titleOptionsList.filter(option => userTitleMap[option].isSpecial && specialTitleList.includes(option))
      // .forEach(titleKey => availableTitleList.push(userTitleMap[titleKey].shortDisplayText));
  // }

  // 去除重複並排序
  const result = [...new Set(availableTitleList)].sort((a, b) => {
    if (a === "") return -1;
    if (b === "") return 1;
    return a.localeCompare(b, 'zh-TW');
  })
  return result;
};
